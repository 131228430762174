import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import LogoImg from "../../public/images/logo.gif"

import BtnContactBack from "../components/btnContactBack"



const PageContact = (props) => (
  <Layout>
    <SEO title="Contact" />
		<div className="box-logo">
			<div className="inner-wrap">
				<section>
					<Link to="/"><img src={LogoImg} className="logo ease" /></Link>
				</section>
			</div>
		</div>
    <div className="sect-contact activated">
			<BtnContactBack></BtnContactBack>
		<div className="container">
			<div className="inner">
				<h2>Hello :)</h2>
				<div className="col-desc">
					<dl className="row">
						<dt className="col-sm-12 col-xs-12">
							<h3>E-mail&nbsp;</h3>
						</dt>
						<dd className="col-sm-12 col-xs-12">
							<a href="mailto:hello@next-pixo.com?subject=Contact Next Pixo&body=Say something to us...">hello@next-pixo.com</a>
						</dd>
					</dl>
					<dl className="row">
						<dt className="col-sm-12 col-xs-12">
									<h3>Tel&nbsp;</h3>
								</dt>
						<dd className="col-sm-12 col-xs-12">
							+852 9131 3922
						</dd>
					</dl>
          <dl className="row">
						<dt className="col-sm-12 col-xs-12">
									<h3>Address</h3>
								</dt>
						<dd className="col-sm-12 col-xs-12">
							<a href="https://goo.gl/maps/E9Z5k84j65VoPqAB7" target="_blank">A12 22/F, Prince Industrial Building, 106 King Fuk Street, San Po Kong, Kowloon, Hong Kong</a>
						</dd>
					</dl>

					<dl className="row">
						<dt className="col-sm-12 col-xs-12">
											<h3>We believe</h3>
										</dt>
						<dd className="col-sm-12 col-xs-12">
							We cannot accomplish all that we need to do without working together.
						</dd>
					</dl>
					<ul className="bar-btm">
						<li>
							<a href="https://www.facebook.com/next.pixo/" className="btn-circle" target="_blank"><i className="fa fa-facebook" aria-hidden="true"></i></a>
						</li>
					</ul>
				</div>
			</div>

			<div className="box-bg">
				<span className="bg bg-animate"></span>
			</div>
		</div>
	</div>

  </Layout>
)

export default PageContact
