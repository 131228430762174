import React, { Component } from "react"
import { withGlobalState } from 'react-globally'
import { globalHistory } from '@reach/router'

class BtnBack extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      isFirstInit: false
    }
  }

  componentWillMount(){
    const firstAccessPage = globalHistory.transitioning
    if (!firstAccessPage) {
      this.setState({
        isFirstInit: true
      })
    }
  }

  render() {

    function HistoryBack(){
       window.history.go(-1)
    }

    if(!this.state.isFirstInit){
      return (
        <button className="btn-back top activated" onClick={HistoryBack}><i className="fa fa-angle-left ease" aria-hidden="true"></i> Back</button>
      )
    }else{
      return (
        <>
        </>
      )
    }

  }
}

export default withGlobalState(BtnBack)
